

import Axios from "../Config/basics"
import {
    addSiteType,
    BodyType, delSiteType,
    getSiteListType,
    HeaderType
} from "@/Api/SocialCircle/interfaceType";

// 自提点列表
export function addSite ( headers : HeaderType , body:addSiteType ):Promise<any>{
    return (
        Axios({
            url:"/app/address/createAddress",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 自提点列表
export function updateSite ( headers : HeaderType , body:addSiteType ):Promise<any>{
    return (
        Axios({
            url:"/",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 自提点列表
export function getSiteList ( headers : HeaderType , body:getSiteListType ):Promise<any>{
    return (
        Axios({
            url:"/app/address/queryAddress",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 删除自提点
export function delSite ( headers : HeaderType , body:delSiteType ):Promise<any>{
    return (
        Axios({
            url:"/app/address/deleteAddress",
            method:"POST",
            headers,
            data:body
        })
    )
}


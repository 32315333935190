import Storage from "@/util/Storage";
import {delSite, getSiteList} from "@/Api/SocialCircle";
import {Toast} from "vant";

// 自提点列表
export function getPickUpList(body:any = {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    return Promise.resolve(getSiteList({ userId,token },body).then(res=>{
        return res.data
    }))
}

// 删除自提点
export function delPickUp(id:string|number ="") {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    Toast.loading({ duration:0,message:"删除中~~~" })
    return Promise.resolve(delSite({ userId,token },{ id }).then(res=>{
        Toast.clear()
        return res.data
    }))
}
